/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.mentions-legales {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

.mentions-legales p, .mentions-legales sup {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.mentions-legales sup {
  top: 0;
}