/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.skeleton-loader {
  display: block;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(10%, #D6DAE1), color-stop(50%, #fff), color-stop(90%, #D6DAE1));
  background-image: linear-gradient(90deg, #D6DAE1 10%, #fff 50%, #D6DAE1 90%);
  background-size: 200% 100%;
  border-radius: 0.2rem;
  -webkit-animation: skeleton-loader 1.5s infinite linear;
          animation: skeleton-loader 1.5s infinite linear;
}

@-webkit-keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.crescent {
  position: relative;
}

.crescent.isafter::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent::before {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent.medium::before {
  width: 1rem;
  height: 1rem;
  scale: 1.08;
}

.crescent.xlarge::before {
  width: 4rem;
  height: 4rem;
  scale: 1.04;
}

@media print, screen and (min-width: 48em) {
  .crescent.large::before {
    width: 2rem;
    height: 2rem;
  }
  .crescent.medium::before {
    width: 1.5rem;
    height: 1.5rem;
  }
  .crescent.small::before {
    width: 1rem;
    height: 1rem;
  }
}
.crescent.bottom-left::before {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.crescent.top-left::before {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.crescent.top-right::before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.crescent.bottom-right::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.green-medium {
  color: #337F37;
}

.green-medium__bg {
  background: #337F37;
}

.green-light {
  color: #96DC00;
}

.green-light__bg {
  background: #96DC00;
}

.green-dark {
  color: #154734;
}

.green-dark__bg {
  background: #154734;
}

.success-300 {
  color: #3A913F;
}

.success-300__bg {
  background: #3A913F;
}

.purple-100 {
  color: #E6E6F5;
}

.purple-100__bg {
  background: #E6E6F5;
}

.purple-200 {
  color: #D2D2F3;
}

.purple-200__bg {
  background: #D2D2F3;
}

.purple-300 {
  color: #6C54AD;
}

.purple-300__bg {
  background: #6C54AD;
}

.purple-400 {
  color: #2D1446;
}

.purple-400__bg {
  background: #2D1446;
}

.pink-100 {
  color: #FAEBEB;
}

.pink-100__bg {
  background: #FAEBEB;
}

.pink-200 {
  color: #F6D4E3;
}

.pink-200__bg {
  background: #F6D4E3;
}

.pink-300 {
  color: #C83C69;
}

.pink-300__bg {
  background: #C83C69;
}

.pink-400 {
  color: #460528;
}

.pink-400__bg {
  background: #460528;
}

.teal-100 {
  color: #EBF5F0;
}

.teal-100__bg {
  background: #EBF5F0;
}

.teal-200 {
  color: #DCEBDC;
}

.teal-200__bg {
  background: #DCEBDC;
}

.teal-300 {
  color: #267B76;
}

.teal-300__bg {
  background: #267B76;
}

.teal-400 {
  color: #0F373C;
}

.teal-400__bg {
  background: #0F373C;
}

.blue-100 {
  color: #EBF2FA;
}

.blue-100__bg {
  background: #EBF2FA;
}

.blue-200 {
  color: #C3DFF4;
}

.blue-200__bg {
  background: #C3DFF4;
}

.blue-300 {
  color: #1D71AC;
}

.blue-300__bg {
  background: #1D71AC;
}

.blue-400 {
  color: #0F2D4B;
}

.blue-400__bg {
  background: #0F2D4B;
}

.yellow-100 {
  color: #FAF5CD;
}

.yellow-100__bg {
  background: #FAF5CD;
}

.yellow-200 {
  color: #F5E682;
}

.yellow-200__bg {
  background: #F5E682;
}

.yellow-300 {
  color: #F5D31A;
}

.yellow-300__bg {
  background: #F5D31A;
}

.yellow-400 {
  color: #3C3205;
}

.yellow-400__bg {
  background: #3C3205;
}

.coral-100 {
  color: #F9ECE1;
}

.coral-100__bg {
  background: #F9ECE1;
}

.coral-200 {
  color: #F7CBB1;
}

.coral-200__bg {
  background: #F7CBB1;
}

.coral-300 {
  color: #E57049;
}

.coral-300__bg {
  background: #E57049;
}

.coral-400 {
  color: #502305;
}

.coral-400__bg {
  background: #502305;
}

.splide__container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}

.splide__list {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
          justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  -webkit-animation: splide-loading 1s linear infinite;
          animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@-webkit-keyframes splide-loading {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@keyframes splide-loading {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
       user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
          justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
  width: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  z-index: 1;
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}
@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }
  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}
.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

.table_container {
  overflow: hidden;
  border-radius: 1rem;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.table_container__title {
  min-height: 5rem;
  font-size: 1rem;
  text-align: center;
  padding: 1rem 2.5rem 2rem 2.5rem;
  background: #EBF5F0;
}

@media print, screen and (min-width: 48em) {
  .table_container__title {
    min-height: 3rem;
    padding: 1rem 2.5rem 0 2.5rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .table_container__element .hide-table {
    display: none;
  }
}
@media print, screen and (min-width: 48em) {
  .table_container__element .hide-table .desktop-table {
    width: 100%;
  }
}
.table_container__element .title-legend {
  background: #EBF5F0;
}

.table_container__element .rowgroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media print, screen and (min-width: 48em) {
  .table_container__element .rowgroup {
    display: none;
  }
}
.table_container__element .rowgroup__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media print, screen and (max-width: 47.99875em) {
  .table_container__element .rowgroup__container {
    width: 50%;
  }
}
.table_container__element .rowgroup .splide {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.table_container__element .rowgroup .splide__track, .table_container__element .rowgroup .splide__list {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.table_container__element .rowgroup .splide__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.table_container__element .rowgroup .splide__side {
  display: block;
}

.table_container__element .rowgroup_carousel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: unset;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

@media print, screen and (max-width: 47.99875em) {
  .table_container__element .rowgroup_carousel {
    width: 50%;
  }
}
.table_container__element .rowgroup_carousel .splide__arrows {
  width: 100%;
  position: absolute;
  left: 0;
  top: -3rem;
}

.table_container__element .rowgroup_carousel .splide__arrows .splide__arrow {
  background: #154734;
  opacity: 1;
}

.table_container__element .rowgroup_carousel .splide__arrows .splide__arrow:disabled {
  background: #6B7A8C;
  cursor: not-allowed;
}

.table_container__element .rowgroup_carousel .splide__arrows .splide__arrow svg {
  display: none;
}

.table_container__element .rowgroup_carousel .splide__arrows .splide__arrow.i-arrow-left-short {
  left: 1rem;
}

.table_container__element .rowgroup_carousel .splide__arrows .splide__arrow.i-arrow-left-short::before {
  font-size: 2rem;
  color: white;
}

.table_container__element .rowgroup_carousel .splide__arrows .splide__arrow.i-arrow-right-short {
  right: 1rem;
}

.table_container__element .rowgroup_carousel .splide__arrows .splide__arrow.i-arrow-right-short::before {
  font-size: 2rem;
  color: white;
}

.table_container__element .rowgroup_carousel .splide__pagination {
  position: relative;
  margin-top: 3rem;
  gap: 0.3rem;
}

@media print, screen and (max-width: 47.99875em) {
  .table_container__element .rowgroup_carousel .splide__pagination {
    margin-top: 2rem;
  }
}
.table_container__element .rowgroup_carousel .splide__pagination__page {
  width: 0.625rem;
  height: 0.625rem;
  opacity: 1;
  background: #3A913F;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.table_container__element .rowgroup_carousel .splide__pagination__page.is-active {
  width: 1.875rem;
  background: #154734;
  border-radius: 0.625rem;
  opacity: 1;
  -webkit-transform: none;
          transform: none;
}

.table_container__element .rowgroup_carousel .splide__pagination {
  position: absolute;
  bottom: unset;
  margin: unset;
  right: 8.5rem;
  top: -1.5rem;
  width: 100%;
  gap: 0.1rem;
}

.table_container__element .rowgroup_carousel .splide__pagination__page {
  width: 0.5rem;
  height: 0.5rem;
}

.table_container__element .rowgroup_carousel .splide__pagination__page.is-active {
  width: 1.5rem;
  border-radius: 0.5rem;
}

.table_container__element .rowgroup_carousel__col {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.table_container__element .rowgroup_carousel__col-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.table_container__element .rowgroup_carousel__col-row .table_container__element-head, .table_container__element .rowgroup_carousel__col-row .table_container__element-data {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  padding-right: 1rem;
}

.table_container__element .rowgroup_carousel__col-row .table_container__element-head {
  background: #EBF5F0;
}

.table_container__element thead {
  position: relative;
  z-index: 1;
}

.table_container__element thead::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: 0;
  background-color: #EBF5F0;
  z-index: -1;
}

.table_container__element thead tr {
  background: transparent;
}

.table_container__element thead tr th:last-child {
  padding-right: 1rem;
}

.table_container__element-fixedhead {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: -0.01em;
  text-align: left;
}

.table_container__element-head {
  text-align: center;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: -0.01em;
  font-weight: 700;
  color: #337F37;
}

.table_container__element-data {
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-bottom: 1px solid #D6DAE1;
}

.table_container__element tr td:last-child {
  padding-right: 1rem;
}

.table_container__element tr:not(:last-child) {
  position: relative;
}

.table_container__element tr:not(:last-child) th::after, .table_container__element tr:not(:last-child) td::after {
  content: "";
  display: block;
  height: 1px;
  background: #EBF5F0;
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 100%;
}

.table_container__element .title-legend, .table_container__element .fixed-legend {
  padding: 0.75rem 0 0.75rem 1rem;
}

.table_container__element .fixed-legend {
  border-bottom: 1px solid #D6DAE1;
}