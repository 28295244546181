/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.spacer-block {
  display: block;
}

.spacer-block.spacing-xxs {
  height: 0.25rem;
}

.spacer-block.spacing-xs {
  height: 0.5rem;
}

.spacer-block.spacing-s {
  height: 0.75rem;
}

.spacer-block.spacing-m {
  height: 1rem;
}

.spacer-block.spacing-l {
  height: 1.5rem;
}

.spacer-block.spacing-xl {
  height: 2rem;
}

.spacer-block.spacing-2xl {
  height: 2.5rem;
}

.spacer-block.spacing-3xl {
  height: 3rem;
}

.spacer-block.spacing-4xl {
  height: 3.5rem;
}

.spacer-block.spacing-5xl {
  height: 4rem;
}

.spacer-block.spacing-6xl {
  height: 4.5rem;
}

.spacer-block.spacing-7xl {
  height: 5rem;
}

@media print, screen and (max-width: 47.99875em) {
  .spacer-block.mobile-spacing-xxs {
    height: 0.25rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .spacer-block.mobile-spacing-xs {
    height: 0.5rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .spacer-block.mobile-spacing-s {
    height: 0.75rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .spacer-block.mobile-spacing-m {
    height: 1rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .spacer-block.mobile-spacing-l {
    height: 1.5rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .spacer-block.mobile-spacing-xl {
    height: 2rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .spacer-block.mobile-spacing-2xl {
    height: 2.5rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .spacer-block.mobile-spacing-3xl {
    height: 3rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .spacer-block.mobile-spacing-4xl {
    height: 3.5rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .spacer-block.mobile-spacing-5xl {
    height: 4rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .spacer-block.mobile-spacing-6xl {
    height: 4.5rem;
  }
}
@media print, screen and (max-width: 47.99875em) {
  .spacer-block.mobile-spacing-7xl {
    height: 5rem;
  }
}