/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.pedagogical-mention.full-background {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #f2f3f3;
  padding: 0.5rem 1rem;
  width: 100%;
}

@media print, screen and (max-width: 63.99875em) {
  .pedagogical-mention.full-background {
    padding: 0.5rem;
  }
}
.pedagogical-mention.full-background p {
  margin-bottom: 0;
  text-align: center;
  font-size: 1.125rem;
}

@media print, screen and (max-width: 63.99875em) {
  .pedagogical-mention.full-background p {
    font-size: 0.875rem;
  }
}